import api from '@/lib/services/general.service'
import { useToast } from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
const $toast = useToast();

const initialState = () => ({
  SyncAllEmailInProgress: true,
});

export const state = initialState();

export const getters = {
  SyncAllEmailInProgress: s => s.SyncAllEmailInProgress,
};

export const actions = {
  async sync({ commit, state }) {
    const response = await api.toggle('sync/all-emails');
    if (response) {
      commit('SET', response.data);
    }
    await startSyncInterval(commit, state);
  },
};
async function startSyncInterval(commit, Syncing) {
  const interval = setInterval(async () => {
    if (state.SyncAllEmailInProgress) {
      let response = await api.toggle(`sync/all-emails`);
      if (response) {
        commit("SET", response.data)
      }
    } else {
      clearInterval(interval);
    }
  }, 30000);
}

export const mutations = {
  SET(s, isAllEmailsSynced) {
    s.SyncAllEmailInProgress = isAllEmailsSynced;
    console.log(isAllEmailsSynced)
  },
}
export default {
  state,
  getters,
  actions,
  mutations
};